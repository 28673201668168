import React, { useState } from "react";
import { Button, Input } from "antd";
import { motion } from "framer-motion";
import { Link } from "react-router-dom"; // Importer Link

// Importation des fichiers audio des notes
import note1 from "./notes/1.wav";
import note2_ from "./notes/2_.wav";
import note2 from "./notes/2.wav";
import note2a from "./notes/2-.wav";
import note3 from "./notes/3.wav";
import note3a from "./notes/3-.wav";
import note4_ from "./notes/4_.wav";
import note4 from "./notes/4.wav";
import musique from "./notes/musique.wav";
import kwack from "./notes/kwack.mp3"; // Importation du fichier audio kwack
import indice from "./notes/indice.wav"; // Importation du fichier audio indice

import "./Monsieur.css";

const correctSequence = ["4_", "4", "3", "2", "2_", "2-", "2-", "3-", "1"];

const playNote = (note, noteName, sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled) => {
  const audio = new Audio(note);
  audio.play();
  let newSequence = [...sequence, noteName];
  if (!correctSequence.slice(0, newSequence.length).every((val, index) => val === newSequence[index])) {
    newSequence = [noteName]; // Réinitialiser la séquence et stocker le premier élément
  }
  setSequence(newSequence);
  if (newSequence.join() === correctSequence.join()) {
    setShowInput(true);
    setMusicPlayed(true);
    const musicAudio = new Audio(musique);
    musicAudio.play();
    setMusicButtonDisabled(true);
    setTimeout(() => setMusicButtonDisabled(false), 13000); // Désactiver le bouton pendant 13 secondes
  }
};

const Monsieur = () => {
  const [sequence, setSequence] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [musicPlayed, setMusicPlayed] = useState(false);
  const [musicButtonDisabled, setMusicButtonDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleReplayMusic = () => {
    const musicAudio = new Audio(musique);
    musicAudio.play();
    setMusicButtonDisabled(true);
    setTimeout(() => setMusicButtonDisabled(false), 13000); // Désactiver le bouton pendant 13 secondes
  };

  const handlePlayIndice = () => {
    const indiceAudio = new Audio(indice);
    indiceAudio.play();
    setMusicButtonDisabled(true);
    setTimeout(() => setMusicButtonDisabled(false), 13000); // Désactiver le bouton pendant 13 secondes
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      <motion.div
        className="button-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note4_, "4_", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🐶</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note2, "2", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🐇</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note3, "3", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🐭</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note1, "1", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🐔</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note2_, "2_", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🦌</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note4, "4", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🦔</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => new Audio(kwack).play()}>🦆</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note2a, "2-", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🦇</Button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button type="primary" onClick={() => playNote(note3a, "3-", sequence, setSequence, setShowInput, setMusicPlayed, setMusicButtonDisabled)}>🐼</Button>
        </motion.div>
      </motion.div>
      {showInput && (
        <motion.div
          className="input-container"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Input className="input-field" placeholder="Nom du groupe ?" value={inputValue} onChange={handleInputChange} />
          {inputValue.toLowerCase() === "linkin park" ? (
            <Link to="/linkinpark">
              <Button type="success">Valider</Button>
            </Link>
          ) : (
            <Button type="success" disabled>Valider</Button>
          )}
        </motion.div>
      )}
      {musicPlayed && (
        <motion.div
          className="music-button-container"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Button type="primary" onClick={handleReplayMusic} disabled={musicButtonDisabled}>Rejouer la musique</Button>
          <Button type="primary" onClick={handlePlayIndice} disabled={musicButtonDisabled}>Indice</Button>
        </motion.div>
      )}
    </div>
  );
};

export default Monsieur;