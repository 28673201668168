import React, { useEffect, useState } from 'react';
import './LinkinPark.css'; // Assurez-vous d'avoir un fichier CSS pour les styles

import Linkinpark from './videos/LinkinPark.mp4'
const LinkinPark = () => {
  return (
    <div className="linkin-park">
      <video width="600" controls>
        <source src={Linkinpark} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LinkinPark;